import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{textAlign:"left"}}>
            Hi Everyone, I am <span className="purple">Eman Alkurdi. </span>
            
            <br />
            I live in <span className="purple">Dubai , UAE.</span>
            <br />
            I am currently employed as a software developer at<span className="purple"> World Advanced Technology Company in Dubai</span>.
            <br />
            I have a Bachelor in  Software Engineering.
            <br />
            <br className="hide-on-mobile" />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Learning Languages
            </li>
            <li className="about-activity">
              <ImPointRight /> Reading Tech Blogs
            </li>
            <li className="about-activity">
              <ImPointRight /> Watching Movies
            </li>
          </ul>

         
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
